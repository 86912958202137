<template>
    <div>
        <v-layout row wrap>
            <v-flex xs5>
                <v-text-field label="Price"
                          v-model="itemPrice"
                          :error-messages="itemPriceErrors"
                          @input="$v.itemPrice.$touch()"
                          @blur="$v.itemPrice.$touch()"
                          @change="itemChanged()">
                </v-text-field>
            </v-flex>
            <v-flex xs5>
                <v-text-field label="Quantity"
                          v-model="quantity"
                          :error-messages="perUnitsErrors"
                          @input="$v.quantity.$touch()"
                          @blur="$v.quantity.$touch()"
                          @change="itemChanged()">
                </v-text-field>
            </v-flex>
            <v-flex xs2 class="container">
                <v-btn icon @click.native="removeRow()" class="vertical-center">
                    <v-icon color="red">fas fa-times</v-icon>
                </v-btn>
            </v-flex>

        </v-layout>
        <v-divider></v-divider>
    </div>
</template>

<script>
    import { validationMixin } from 'vuelidate'
    import { required, minValue } from 'vuelidate/lib/validators'

    export default {
        mixins: [validationMixin],
        props: ["id"],
        data() {
            return {
                itemPrice: 0,
                quantity: 1
            }
        },
        computed: {
            itemPriceErrors() {
                const errors = []
                if (!this.$v.itemPrice.$dirty) return errors
                !this.$v.itemPrice.minValue && errors.push('Item Price must be at least 1')
                !this.$v.itemPrice.required && errors.push('Item Price is required')
                return errors
            },
            perUnitsErrors() {  
                const errors = []
                if (!this.$v.quantity.$dirty) return errors
                !this.$v.quantity.minValue && errors.push('Quantity must be at least 1')
                !this.$v.quantity.required && errors.push('Quantity is required')
                return errors
            },
        },
        methods: {
            removeRow: function () {
                this.$emit('remove', this.id)
            },
            itemChanged: function () {
                this.$emit('updateItem', { id: this.id, itemPrice: this.itemPrice, quantity: this.quantity })
            },
            delayedTrigger: function () {
                setTimeout(this.triggerErrors, 5000)
            },
            triggerErrors: function () {
                this.$v.$touch(); //Triggers Error Messages
            }
        },
        beforeMount() {
            this.delayedTrigger()
        },
        validations: {
            itemPrice: {
                required,
                minValue: minValue(1)
            },
            quantity: {
                required,
                minValue: minValue(1)
            }
        }

    }
</script>

<style scoped>
    .container {
        height: 70px;
        position: relative;
        
    }

    .vertical-center {
        margin: 0;
        position: absolute;
        top: 50%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
    }
</style>